let extended = {
  chatServer: 'https://pjf.cocoho.com/signal',
  chatEnabled: true,
  skipSplash: true,
  couponEnabled: true,
  defaultSkipSplashRoute: '/shop',
  showScan: true,
  icp: {
    link: 'https://beian.miit.gov.cn/#/Integrated/recordQuery'
  },
  contact: {
    merchantEmail: 'merchant@pjfwines.com',
    infoEmail: 'info@pjfwines.com'
  },
  smsEnabled: true,
  poupBannerTimer: 5,
  poupBannerShow: true,
  hideProductPrice: false,
  videoSizeLimit: 100,
  canAddToCart: true,
  miniProgram: false,
  pagesToHideToolBar: ['orderViewPage', 'cartPage', 'chat', 'splash', 'registerPage', 'registerMobileAuthenticationPage', 'registerMobileVerificationPage', 'registerPrimaryPage', 'registerSuccessPage', 'RegisterMobileMergePage', 'registerComplete'],
  pageToHidePopupBanner: ['registerPage', 'loginPage', 'productViewPage'],
  pageToShowRegisterBanner: ['explorePJFPage', 'postViewPage', 'videoViewPage', 'videoPage', 'weeklyRecommendation'],
  isElastic: true,
  oss: {
    enabled: true,
    endpoint: "https://pjf-assets.oss-cn-shanghai.aliyuncs.com"
  },
  isRedirect: null,
  exlucdeCategoriesforBundle: ["BUNDLE_SALE", "GROUP_BUY", "FLASH_SALE"],
  isSignupBtn: true,
  isTaxInclusive: false
}

export default extended
